import React, { useEffect, useState } from "react";
import Asset from "./Asset.js";
import "./index.scss";
import Section from "components/Section";
import Logos from "components/Logos";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";
import SetNavbarStyle from "components/SetNavbarStyle";
import AlbumLink from "components/PhotoAlbum/AlbumLink";
import Authors from "./Authors.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";


import { ReactComponent as Arrow } from "assets/left.svg";
import { setPageData } from "redux/actions";

const Assets = ({ assets, assetTypes, heading, subtitle, noPad, match }) => {
  let [items, setItems] = useState(null)
  let [parent, setParent] = useState(null)

  if (match && match.params.childSlug) {
    useEffect(() => {
      setParent(match.params.slug)
    },[])
  }

  if (assetTypes || (match && match.params.childSlug)) {
    assetTypes = assetTypes || match.params.childSlug
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API}/api/brandassets.json${assetTypes ? `?types=${assetTypes}` : ''}`)
        .then(r=>r.json())
        .then(({ data }) => setItems(data))
    },[])
  } else {
    items = assets
  }

  const types = items ? items.reduce((r,d) => {
    if (!d.assetType) {
      console.log("missing asset type " + d.assetType, d)
      return r;
    }
    if (!r[d.assetType]) {
      r[d.assetType] = []
    }
    r[d.assetType].push(d);
    return r
  },{}) : {};

  if (items && items.length) {

    const uniqAuthors = new Map()
    items.map(el => {
      let auth = el.authors[0]
      if (auth && !uniqAuthors.has(auth.name)) { uniqAuthors.set(auth.name, auth) }
    })
    const authors = [...uniqAuthors.values()]

    if (items[0].assetType === 'Logo') {
      return <Logos items={items} />
    } else {
      return (<><div className={`Asset-types ${noPad ? 'no-pad' : ''}`}>
        { parent ?
            <Link to={`/${match.params.slug}`} className="font-heading type--black arrow-link" style={{ color: "#000000" }}>
              <Arrow />
              Back to {match.params.slug}
            </Link>
          : ''}
        {Object.keys(types).map(type => {
          let sectionTitle = heading ? heading : (assetTypes ? type : '')
          return <div key={type} className="Asset-type">
            <h2 className="Asset-type-title font-subheading">{sectionTitle}</h2>
            {subtitle ? <p className="Asset-type-subtitle font-body">{subtitle}</p> : null}
            <div className="Assets">{types[type].map(item => <Asset key={item.id} {...item} />)}</div>
          </div>
        })}
      </div>
      {
        authors.length && parent ? <Section className="in" heading="The Artists" explainer="All Team Pete signs were hand lettered, the old-fashioned way, by talented letterers.">
          <Authors items={ authors } />
        </Section>
        : ''
      }
      </>)
    }
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  currentPage: state.data.currentPage
})

const mapDispatchToProps = null

export default connect(null,mapDispatchToProps)(Assets);
