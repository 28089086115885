import React from "react";
import { connect } from "react-redux";
import { setAssetTermsAccepted } from "redux/actions";
import { ReactComponent as X } from "assets/x.svg";

const mapStateToProps = state => ({
  termsAccepted: state.auth.assetTermsAccepted,
})
const mapDispatchToProps = dispatch => ({
  acceptTerms: () => {
    dispatch(setAssetTermsAccepted(true))
  },
  denyTerms: () => {
    dispatch(setAssetTermsAccepted(false))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(({
  termsAccepted,
  acceptTerms,
  denyTerms,
  onNo,
  childOverride,
  children
}) => {
  return termsAccepted ? (children ? children : '') : (
    <div className="ConfirmAssetTerms" onClick={(e) => {
      e.stopPropagation()
    }}>
      <div className="ConfirmAssetTerms-inner">
        <h1 className="font-heading">Terms of Use</h1>
        <p>Pete For America, Inc. (“PFA”) grants you a non-exclusive, non-transferrable, non-sublicensable, royalty-free limited license to use the Logos solely as depicted in the artwork made available for download. You agree to use the Logos for your personal, noncommercial use to show your support for PFA and Mayor Pete Buttigieg on posters, decals and related materials, with the Logos to be depicted as in the downloaded artwork and of commensurate quality.  Any use by you of the Logos other than as specifically authorized here, without the prior written permission of PFA, is strictly prohibited and will terminate the license granted herein.  Commercial use of the Logos is strictly prohibited.  This license is revocable at any time.</p>
        <div className="ConfirmAssetTerms-buttons">
          <button
            className="ConfirmAssetTerms-buttons-item ConfirmAssetTerms-buttons-item--default font-label"
            onClick={() => {
              if (childOverride) {
                acceptTerms()
                onNo()
              } else {
                acceptTerms()
              }
            }}>
            I accept
          </button>
          <button className="ConfirmAssetTerms-buttons-item font-label"
            onClick={() => {
              denyTerms()
              onNo()
            }}>
            No Thanks
          </button>
        </div>
      </div>
      <button
        className="AssetDownload-close"
        onClick={onNo}>
        <X />
      </button>
    </div>
  )
})
