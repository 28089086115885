import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setSandboxLogo } from "redux/actions";
//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./index.scss";
import DownloadSvg from "components/DownloadSvg";
import { ReactComponent as Down } from "assets/down.svg";
import Tooltip from "react-tooltip";

import { styleComponent } from "helpers.js";

const DEFAULT_TEXT_ITEMS = [
  { Component: () => <div className="SandboxSample-samples-item font-label">
    <span className="ff-industry">Generational Change</span>
  </div>},
  { Component: () => <div className="SandboxSample-samples-item font-hero-heading">A Fresh Start For America</div> },
  { Component: () => <div className="SandboxSample-samples-item font-subheading">
    Freedom, Democracy, Security
  </div>},
  { Component: () => <div className="SandboxSample-samples-item font-intro">
    It's time for a new generation of American leadership in this country.
  </div>},
  { Component: () => <div className="SandboxSample-samples-item font-body">
    <span className="ff-domaine">America is more than a broken Washington, D.C. and 2020 is about more than the next four years. Our country is changing, and what matters most is how we will rise to meet the challenges ahead in our economy, our society, and our politics.</span>
  </div>}
];

const SandboxSample = ({
  sampleType,
  color,
  secondaryColor,
  backgroundColor,
  sandboxLogo,
  logoItems,
  logos,
  setLogoItems
}) => {
  const textItems = DEFAULT_TEXT_ITEMS

  const [items,setItems] = useState([]);
  const [choices,setChoices] = useState();
  const [actions,setActions] = useState();

  const style = {
    backgroundColor: backgroundColor.hex,
    color: color.hex
  }

  useEffect(() => {
    switch (sampleType) {
      case "text":
        setItems(textItems)
        setChoices(null)
        setActions(null)
        break;

      case "logo":
        setItems(logoItems)
        setChoices(<LogoChoices
          setItems={setItems}
          setLogoItems={setLogoItems}
          logos={logos}
          logoStyle={style} />
        )
        break;
      default:
    }
  }, [sampleType, items, color.hex, backgroundColor.hex, secondaryColor])

  useEffect(() => {
    switch (sampleType) {
      case "text":
        setActions(null)
        break;

      case "logo":
        if (items.length) {
          setActions(
            <LogoActions
              logo={items[0]}
              logoStyle={style}
              actionsStyle={{
                backgroundColor: backgroundColor.hex,
                color: color.hex,
                stroke: color.hex
              }}
              color={color}
              secondaryColor={secondaryColor}
              backgroundColor={backgroundColor}
              colorName={color.key}
              secondaryColorName={secondaryColor ? secondaryColor.key : null}
              backgroundColorName={backgroundColor.key}
              stroke={color.hex} />
          )
        }
        break;
      default:
    }

    Tooltip.rebuild()

  }, [items, color.hex, backgroundColor.hex, secondaryColor])

  return (
    <div
      className={`SandboxSample ${backgroundColor.key === "transparent" ? "SandboxSample--border" : ""}`}
      style={style}>
      {choices}
      <style dangerouslySetInnerHTML={{ __html: `
        .SandboxSample {
          fill: ${color.hex};
        }
        ${secondaryColor && `
          .SandboxSample #secondary {
            fill: ${secondaryColor.hex};
          }
        `}
      `}} />
      <div className="SandboxSample-samples">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className="SandboxSample-samples-item"
              >
              <item.Component />
            </div>
          )
        })}
        {actions}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const colors = state.data.color.items;
  return {
    logoItems: state.sandbox.logo,
    logos: state.data.logo.items,
    color: colors[state.sandbox.foregroundColor],
    secondaryColor: colors[state.sandbox.secondaryColor],
    backgroundColor: colors[state.sandbox.backgroundColor],
    sampleType: state.sandbox.mode
  }
}
const mapDispatchToProps = dispatch => ({
  setLogoItems: (value) => {
    dispatch(setSandboxLogo(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(SandboxSample);

const LogoChoices = ({ logos, setItems, setLogoItems, logoStyle, choicesStyle }) => {
  return <div className="SandboxSample-choices" style={choicesStyle}>
    {logos.map(logo => (
      <div key={logo.name} data-tip={logo.name} className="SandboxSample-choices-choice" onClick={() => {
        setItems([logo])
        setLogoItems([logo])
    }}>
        <logo.Component style={logoStyle} />
      </div>
    ))}
  </div>
}

const LogoActions = ({
  logo,
  color,
  secondaryColor,
  backgroundColor,
  actionsStyle,
  logoStyle,
  stroke
}) => {
  const [hovered,setHovered] = useState(false)
  function onMouseEnter() {
    setHovered(true)
  }
  function onMouseLeave() {
    setHovered(false)
  }
  const actionsStyleHovered = {
    color: actionsStyle.backgroundColor === "transparent" ? "#fff" : actionsStyle.backgroundColor,
    fill: actionsStyle.backgroundColor,
    backgroundColor: actionsStyle.color,
  }
  const style = hovered ? actionsStyleHovered : actionsStyle;

  const markup = styleComponent(logo.Component,logo.name,color,secondaryColor,backgroundColor)

  return (
    <div
      className="SandboxSample-actions"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <DownloadSvg
        name={logo.name}
        logoMarkup={markup}
        twotoned={logo.twotoned}
        color={color.key}
        secondaryColor={secondaryColor ? secondaryColor.key : null}
        backgroundColor={backgroundColor.key}
        dynamicAsset="true">
        <div className="SandboxSample-actions-download-mark font-action" style={{
          stroke: style.color,
          ...style
        }}>Download <Down /></div>
      </DownloadSvg>
    </div>
  )
}
