import { combineReducers } from "redux";
import sandbox from "./sandbox.js";
import assetDownload from "./assetDownload.js";
import auth from "./auth.js";
import data from "./data.js";
import style from "./style.js";

export default combineReducers({
  sandbox,
  assetDownload,
  auth,
  data,
  style
});
