const defaultState = {
  authenticated: true,
  assetTermsAccepted: false
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SET_AUTHENTICATED":
      return {
        ...state,
        authenticated: action.value
      }

    case "SET_ASSET_TERMS_ACCEPTED":
      return {
        ...state,
        assetTermsAccepted: action.value
      }

    default:
      return state
  }
}