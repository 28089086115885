import React from "react";

const Authors = ({ items }) => {
  return items ? (
    <div className="Authors">
      {items.map(item => <Author key={item.id} {...item} />)}
    </div>
  ) : null
}

const Author = ({ name, photo, hometown, website }) => {
  return (
    <div className="Authors-item">
      <img className="Authors-item-photo" alt={name} src={photo} />
      <div className="Authors-item-name">
        <a href={website} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </div>
      <div className="Authors-item-location">{hometown}</div>
    </div>
  )
}

export default Authors;
