import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Masonry from 'react-masonry-component';
import { useSpring, animated } from "react-spring";
import { ReactComponent as Arrow } from "assets/left.svg";
import { ReactComponent as X } from "assets/x.svg";
import { ReactComponent as Down } from "assets/down.svg";
import Section from "components/Section";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";
import SetNavbarStyle from "components/SetNavbarStyle";
import Image from "components/Image";
import { DownloadFormat } from "components/AssetDownload/Formats";
import ConfirmAssetTerms from "components/AssetDownload/ConfirmAssetTerms.js";
import { isMobile } from "helpers";
// import store from "redux/store";
import "./index.scss";
import {
  setAssetModalOpen,
  setAssetTermsAccepted,
  setPhotoData
} from "redux/actions";

const masonryOptions = {
  transitionDuration: '0.1',
  gutter: isMobile ? 5 : 10,
  itemSelector: '.PhotoAlbum-Photo',
  percentPosition: true
};

const photoStyle = {
  width: `calc(33% - ${isMobile ? 2.5 : 5}px)`,
  marginBottom: isMobile ? 5 : 10
}

const imagesLoadedOptions = {
  backgroundColor: '#1d253c'
}

const loadImages = (e) => {
  // console.log(e)
  // if (e.images.length) {
  //   e.images.map(img => img.img.classList.remove('loading'))
  // }
}

const PhotoAlbum = ({ match, onRequestClose, onRequestOpen, isOpen, termsAccepted }) => {
  const [data,setData] = useState()
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/photoalbum/${match.params.id}.json`)
    .then(response => response.json())
    .then(setData)
  }, [])

  if (data) {
    let photoData = data.data[0]
    const {photos, title} = photoData

    const childElements = photos ? photos.map(image =>
      <PhotoDownload key={image.id} termsAccepted={termsAccepted} onRequestOpen={onRequestOpen} {...image} />
    ) : ''

    return (
      <section className="Section-pad PhotoAlbum">
        <ScrollToTopOnMount routeChange={match} />
        <SetNavbarStyle theme="light" navLogoVisible={true} />
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}>
        </Modal>
        {isOpen && <button className="AssetDownload-close" onClick={onRequestClose}>
          <X />
        </button>}
        <Section className="in">
          <Link to="/photography" className="font-heading type--black arrow-link" style={{ color: "#000000" }}>
            <Arrow />
            Photography
          </Link>
          <h3 className="font-intro">{title}</h3>
          <Masonry
            className={'PhotoAlbum-Photos list--reset'}
            elementType={'ul'}
            options={masonryOptions}
            // onImagesLoaded={loadImages.bind(this)}
            disableImagesLoaded={false}
            updateOnEachImageLoad={true}
          >
            {childElements}
          </Masonry>
        </Section>
      </section>
    )
  } else {
    return "Loading"
  }
}

const PhotoDownload = ({id, name, title, srcset, src, termsAccepted, downloadUrl, onRequestOpen}) => {
  if (termsAccepted) {
    return (<li className="PhotoAlbum-Photo" style={photoStyle}>
      <DownloadFormat href={downloadUrl} name={name}>
        <Image src={src} srcset={srcset} title={title} />
        <span className="hover-mark" />
      </DownloadFormat>
    </li>)
  } else {
    return (<li className="PhotoAlbum-Photo" style={photoStyle}>
      <div onClick={onRequestOpen}>
        <Image src={src} srcset={srcset} title={title} />
      </div>
    </li>)
  }
}

const Modal = ({ isOpen, onRequestClose }) => {
  const [style,setStyle] = useSpring(() => ({
    transform: 'translateY(100%)'
  }))

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''
  },[isOpen])

  setStyle({
    transform: isOpen ? 'translateY(0%)' : 'translateY(100%)'
  })

  return <animated.div
    className="AssetModal"
    style={{
      ...style,
      backgroundColor: 'rgba(242,186,66,0.9)',
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      justifyContent: 'center',
      height: '100vh',
      right: 0,
      overflowY: 'auto',
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      width: '100%',
      top: '0',
      zIndex: 2
    }}
    onClick={() => { onRequestClose() }}>
    <div className="AssetModal-inner" style={{ display: 'flex' }} onClick={(e) => { onRequestClose() }}>
      <ConfirmAssetTerms onNo={onRequestClose} childOverride={true}></ConfirmAssetTerms>
    </div>
  </animated.div>
}

const mapStateToProps = state => ({
  isOpen: state.assetDownload.modalIsOpen,
  termsAccepted: state.auth.assetTermsAccepted
})

const mapDispatchToProps = dispatch => ({
  onRequestClose: () => {
    dispatch(setAssetModalOpen(false))
  },
  onRequestOpen: () => {
    dispatch(setAssetModalOpen(true))
  }
})

export default connect(mapStateToProps,mapDispatchToProps)(PhotoAlbum);
