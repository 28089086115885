import React, { useEffect } from "react";
import tinycolor from "tinycolor2";
import { ReactComponent as X } from "assets/x.svg";
import { ReactComponent as CheckMark } from "assets/check.svg";
import Tooltip from "react-tooltip";
import { ReactComponent as InfoIcon } from "assets/info.svg"

export default ({ colors }) => {
  if (colors.includes('transparent')) {
    return null
  }

  useEffect(() => {
    Tooltip.rebuild()
  }, [])

  const tooltipCopy = `Pete for America is committed to creating inclusive digital products that meet WCAG2 level AA accessibility standards. Adhering to color contrast criteria is vital to ensure  that all people can perceive content on the page whether or not they experience visual disabilities.`
  const contrast = tinycolor.readability(colors[0],colors[1]);
  return (
    <div className="Contrast">
      <div className="font-control-label font-color-label--icon">
        <a href="https://www.w3.org/WAI/WCAG21/quickref/?versions=2.0#qr-visual-audio-contrast-contrast"
          target="_blank"
          rel="noopener noreferrer">
          Color Contrast
        </a>
        <button
          data-tip={tooltipCopy}
          className="reset-button Contrast-info">
          <InfoIcon />
        </button>
      </div>
      <div className="Contrast-values">
        <div className="Contrast-values-item">{contrast.toFixed(1)}:1</div>
        <div className="Contrast-values-item"><Check checked={tinycolor.isReadable(colors[0],colors[1],{level:"AA", size:"small"})} /> Small Text</div>
        <div className="Contrast-values-item"><Check checked={tinycolor.isReadable(colors[0],colors[1],{level:"AA", size:"large"})} /> Large Text</div>
      </div>
    </div>
  )
}

const Check = ({ checked }) => checked ? <CheckMark stroke="green" /> : <X stroke="red" />
