import React from "react";
//import LogoMorph from "components/LogoMorph";
import { animated } from "react-spring";
import "./index.scss";
import { ReactComponent as Team } from "assets/team.svg";

import data from "data.js";

const style = {
  backgroundColor: data.color.items.heartland.hex,
  fill: data.color.items.skagen.hex,
  color: data.color.items.skagen.hex
}

export default ({ copy }) => {
  const homepageCopy = copy ? copy : "<h2 className='Hero-heading-subtitle font-subheading'>Design Toolkit</h2><p>This is your home base for downloading the graphic assets you need to support our grassroots campaign. Show us what you make using #TeamPete.</p>"
  return (
    <animated.div className="Hero" style={style}>
      <div className="Hero-heading">
        <h1 className="Hero-heading-title Hero-heading-logo font-hero-heading"><Team /></h1>
        <div className="Hero-heading-copy ff-aktiv" dangerouslySetInnerHTML={{ __html: homepageCopy }} />
      </div>
    </animated.div>
  )
}
