import data from "data.js";
const defaultState = {
  ...data
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "GET_PAGE_DATA":
      return {
        ...state,
        pages: action.value
      }

    case "SET_PAGE_DATA":
      return {
        ...state,
        currentPage: action.value
      }

    case "SET_PHOTO_DATA":
      return {
        ...state,
        photoAlbum: action.value
      }

    default:
      return state
  }
}
