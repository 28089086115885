import React from "react";

export default ({
  src,
  srcset,
  title,
  className="",
  ...image
}) => (
  <img
    alt={title}
    className={`${className} lazyload`}
    src={src}
    data-srcset={srcset}
    sizes="100vw"
    {...image}
  />
)

// sizes:
// 1200w
// 992w
// 768w
// 576w
