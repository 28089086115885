const defaultState = {
  modalIsOpen: false,
  assetName: "Pete Bridge 2020",
  assetMarkup: null,
  assetIsTwotoned: null,
  primaryColor: "skagen",
  secondaryColor: null,
  backgroundColor: "strato"
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SET_ASSET_MODAL_OPEN":
      return {
        ...state,
        modalIsOpen: action.value
      }

    case "SET_ASSET_MODAL_ASSET_NAME":
      return {
        ...state,
        assetName: action.value
      }

    case "SET_ASSET_MODAL_ASSET_MARKUP":
      return {
        ...state,
        assetMarkup: action.value
      }

    case "SET_ASSET_MODAL_ASSET_IS_TWOTONED":
      return {
        ...state,
        assetIsTwotoned: action.value
      }

    case "SET_ASSET_MODAL_ASSET_PRIMARY_COLOR":
      return {
        ...state,
        primaryColor: action.value
      }

    case "SET_ASSET_MODAL_ASSET_SECONDARY_COLOR":
      return {
        ...state,
        secondaryColor: action.value
      }

    case "SET_ASSET_MODAL_ASSET_BACKGROUND_COLOR":
      return {
        ...state,
        backgroundColor: action.value
      }

    case "SET_ASSET_MODAL_ASSET_DYNAMIC":
      return {
        ...state,
        dynamicAsset: action.value
      }

    default:
      return state
  }
}
