import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Hero from "components/Hero";
import Nav from "components/Nav";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";
import SetNavbarStyle from "components/SetNavbarStyle";

const Home = ({ data }) => {
  const [copy,setCopy] = useState()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/page/home.json`)
      .then(response => response.json())
      .then(json => setCopy(json))
  },[])

  return (
    <div className="Home">
      <ScrollToTopOnMount />
      <SetNavbarStyle theme="home" navLogoVisible={false} />
      <Hero copy={copy ? copy.body : null} />
      <Nav data={data} />
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.data
})
const mapDispatchToProps = null
export default connect(mapStateToProps,mapDispatchToProps)(Home);
