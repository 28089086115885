import React from "react";
import PeteBridge2020Src, { ReactComponent as PeteBridge2020 } from "assets/logos/Pete_Bridge_2020.svg";
import PeteBridgeSrc, { ReactComponent as PeteBridge } from "assets/logos/Pete_Bridge.svg";
import TeamPeteSrc, { ReactComponent as AssetPage } from "assets/logos/Team_Pete.svg";
import BootEdgeEdgeSrc, { ReactComponent as BootEdgeEdge } from "assets/logos/Boot_Edge_Edge.svg";
import DemocratForPresidentSrc, { ReactComponent as DemocratForPresident } from "assets/logos/Pete Buttigieg for President.svg";
import ParaPresidenteSrc, { ReactComponent as ParaPresidente } from "assets/logos/Pete Buttigieg Para Presidente.svg";

import stratoStory from "assets/colorstory/Strato.jpg";
import claeysStory from "assets/colorstory/Claeys.jpg";
import riverStory from "assets/colorstory/River.jpg";
import heartlandStory from "assets/colorstory/Heartland.jpg";
import skyStory from "assets/colorstory/Calm.jpg";
import rustStory from "assets/colorstory/RustandSky.jpg";
import dogStory from "assets/colorstory/BuddyTruman.jpg"

// import { ReactComponent as Logo } from "assets/logos/Pete_Bridge_2020.svg";
// import { ReactComponent as Type } from "assets/type.svg";
// import { ReactComponent as Color } from "assets/color.svg";
// import { ReactComponent as Team } from "assets/team_nav.svg";
// import { ReactComponent as Pride } from "assets/pride.svg";

const data = {
  logo: {
    slug: "logos",
    heading: "Logos",
    explainer: "We’ve got tons of ways for you to show your support for Pete! Use our color pairing tool to select approved color combinations for all of our campaign logos and download them in JPG, PNG or SVG format.",
    items: [
      {
        name: "Pete Bridge 2020",
        Component: PeteBridge2020,
        src: PeteBridge2020Src,
        size: 'half',
        group: 'Primary Logo',
        twotoned: true,
        defaultColors: {
          backgroundColor: "skagen",
          primaryColor: "claeys",
          secondaryColor: "heartland"
        }
      },
      {
        name: "Pete Bridge",
        Component: PeteBridge,
        src: PeteBridgeSrc,
        size: 'half',
        group: 'Primary Logo',
        defaultColors: {
          backgroundColor: "claeys",
          primaryColor: "strato",
        }
      },
      {
        name: "Boot Edge Edge",
        Component: BootEdgeEdge,
        src: BootEdgeEdgeSrc,
        size: 'half',
        group: 'Primary Logo',
        defaultColors: {
          backgroundColor: "strato",
          primaryColor: "clearSkies"
        }
      },
      {
        name: "Pete Buttigieg for President",
        Component: DemocratForPresident,
        src: DemocratForPresidentSrc,
        size: 'half',
        group: 'Primary Logo',
        defaultColors: {
          backgroundColor: "clearSkies",
          primaryColor: "strato"
        }
      },
      {
        name: "Pete Buttigieg para Presidente",
        Component: ParaPresidente,
        src: ParaPresidenteSrc,
        size: 'half',
        group: 'Primary Logo',
        defaultColors: {
          backgroundColor: "heartland",
          primaryColor: "strato"
        }
      },
      {
        name: "Team Pete",
        Component: AssetPage,
        src: TeamPeteSrc,
        size: 'half',
        group: 'Primary Logo',
        defaultColors: {
          backgroundColor: 'rustBelt',
          primaryColor: 'strato'
        }
      },
    ],
    navCopy: "Logos",
    // NavImage: Logo,
    navBackgroundColor: "strato",
    navForegroundColor: "claeys"
  },
  color: {
    slug: "colors",
    heading: "Colors",
    explainer: <p>Rather than adopt the default red, white and blue color palette of past presidential candidates, our color palette is deeply rooted in  Pete’s home town – <a href="https://onthegrid.city/south-bend/south-bend" target="_blank" rel="noopener noreferrer">South Bend, Indiana.</a> Born and raised in South Bend, Mayor Pete has led the rust-belt, midwestern city through a period of renaissance since he took office. The nine colors in our palette are an ode to Pete’s hometown and his life there.</p>,
    contrastExplainer: "Colors are paired using high contrast combinations that have been approved for use based on USA web accessibility standards. Once choosing the forground color, compatible color options can be chosen for the background.",
    items: {
      strato: {
        key: "strato",
        name: "Strato Blue",
        role: "primary",
        hex: "#1d253c",
        cmyk: [100,73,28,86],
        pms: "296c",
        foregrounds: [
          "claeys",
          "heartland",
          "clearSkies",
          "calm",
          "buddy",
          "rustBelt",
          ["claeys","buddy"],
          ["claeys","heartland"],
          ["claeys","calm"],
          ["claeys","rustBelt"],
          ["clearSkies","buddy"],
          ["clearSkies","heartland"],
          ["clearSkies","calm"],
          ["clearSkies","rustBelt"],
        ]
      },
      claeys: {
        key: "claeys",
        name: "Claeys Cream",
        hex: "#f2e4d6",
        cmyk: [0, 10, 12, 5],
        role: "primary",
        pms: "9225c",
        foregrounds: [
          "strato",
          "rustBelt",
          ["strato","rustBelt"],
          ["strato","heartland"],
          "skagen",
          ["skagen","buddy"],
          ["skagen","rustBelt"],
          ["skagen","heartland"],
          "truman",
          ["rustBelt","strato"],
          ["rustBelt","calm"],
        ]
      },
      skagen: {
        key: "skagen",
        name: "River Blue",
        hex: "#004d80",
        cmyk: [100,43,0,30],
        role: "primary",
        pms: "7691c",
        foregrounds: [
          "claeys",
          ["claeys","heartland"],
          ["claeys","buddy"],
          ["claeys","rustBelt"],
          ["clearSkies","heartland"],
          ["clearSkies","buddy"],
          ["clearSkies","rustBelt"],
          "heartland",
          "clearSkies"
        ]
      },
      heartland: {
        key: "heartland",
        name: "Heartland Yellow",
        hex: "#f2ba42",
        cmyk: [0,35,85,0],
        role: "primary",
        pms: "123c",
        foregrounds: [
          "strato",
          ["strato","skagen"],
          "skagen",
          "truman"
        ]
      },
      clearSkies: {
        key: "clearSkies",
        name: "Calm Blue",
        hex: "#eef4f8",
        cmyk: [4, 2, 0, 3],
        role: "secondary",
        pms: "649c",
        foregrounds: [
          "strato",
          ["strato","buddy"],
          ["strato","heartland"],
          ["strato","rustBelt"],
          "skagen",
          ["skagen","buddy"],
          ["skagen","heartland"],
          ["skagen","rustBelt"],
          "rustBelt",
          // ["rustBelt","skagen"],
          // ["rustBelt","strato"],
          "truman"
        ]
      },
      calm: {
        key: "calm",
        name: "Blue Sky",
        hex: "#85b6c5",
        cmyk: [32, 8, 0, 23],
        role: "secondary",
        pms: "7458c",
        foregrounds: [
          "strato",
          ["strato","clearSkies"],
          ["strato","claeys"],
          ["clearSkies","strato"],
          ["clearSkies","truman"],
          ["clearSkies","buddy"],
          ["clearSkies","rustBelt"],
          ["claeys","strato"],
          ["claeys","truman"],
          "truman",
          "rustBelt"
        ]
      },
      rustBelt: {
        key: "rustBelt",
        name: "Rust Belt",
        hex: "#d34e23",
        cmyk: [0,73,98,0],
        role: "secondary",
        pms: "1665c",
        foregrounds: [
          "claeys",
          ["claeys","strato"],
          ["claeys","skagen"],
          ["claeys","heartland"],
          ["clearSkies","strato"],
          ["clearSkies","skagen"],
          "clearSkies",
          "strato"
        ]
      },
      buddy: {
        key: "buddy",
        name: "Buddy Gold",
        hex: "#b88a57",
        cmyk: [24, 41, 59, 1],
        role: "secondary",
        pms: "7562c",
        foregrounds: [
          "strato",
          "rustBelt",
          "clearSkies",
          "claeys",
          ["claeys","strato"],
          ["claeys","rustBelt"],
          ["clearSkies","strato"],
          ["clearSkies","rustBelt"],
        ]
      },
      truman: {
        key: "truman",
        name: "Truman Brown",
        hex: "#653727",
        cmyk: [0, 46, 61, 60],
        role: "secondary",
        pms: "725c",
        foregrounds: [
          "claeys",
          ["claeys","heartland"],
          ["calm","heartland"],
          ["clearSkies","heartland"],
          "heartland",
          "clearSkies",
          "calm",
        ]
      },
      transparent: {
        key: "transparent",
        name: "Transparent (SVG, PNG only)",
        role: null,
        hex: "transparent",
        hidden: true,
        foregrounds: [
          "buddy",
          "calm",
          "claeys",
          ["claeys","buddy"],
          ["claeys","calm"],
          ["claeys","heartland"],
          ["claeys","rustBelt"],
          ["claeys","strato"],
          ["claeys","truman"],
          "clearSkies",
          ["clearSkies","buddy"],
          ["clearSkies","calm"],
          ["clearSkies","heartland"],
          ["clearSkies","rustBelt"],
          ["clearSkies","strato"],
          ["clearSkies","truman"],
          "heartland",
          "rustBelt",
          ["rustBelt","calm"],
          ["rustBelt","strato"],
          "skagen",
          ["skagen","buddy"],
          ["skagen","heartland"],
          ["skagen","rustBelt"],
          "strato",
          ["strato","buddy"],
          ["strato","claeys"],
          ["strato","clearSkies"],
          ["strato","heartland"],
          ["strato","rustBelt"],
          "truman",
        ]
      }
    },
    navCopy: "Colors",
    // NavImage: Color,
    navBackgroundColor: "clearSkies",
    colorStories: [
      {
        color: ["strato"],
        text: "Strato Blue was one of the available colors in the 1964 line of Studebakers, an American automobile manufacturer founded and headquartered in South Bend, Indiana in 1852. Studebaker closed its doors in 1967 but still forms an important part of the local identity as can be experienced at the Studebaker Museum. The redevelopment of a former Studebaker plant is a cornerstone of the city's Renaissance District.",
        image: stratoStory
      },
      {
        color: ["claeys"],
        text: <p><a href="http://www.claeyscandy.com/" target="_blank" rel="noopener noreferrer">Claeys Candy</a> is a family-owned company that makes Old-Fashioned American Hard Candy. Founded in a South Bend garage in 1919, Claeys Candy has been making high-quality treats in the city for 100 years.</p>,
        image: claeysStory
      },
      {
        color: ["skagen"],
        text: "In 2015, the South Bend River Lights were unveiled over St. Joseph’s River to commemorate the city’s 150th Anniversary. The interactive public light sculpture is a symbol of the city’s rebirth under Mayor Pete’s watch.",
        image: riverStory
      },
      {
        color: ["heartland"],
        text: "Yellow is big in Indiana. It is prominently featured in both the state flag and in South Bend's modern new  flag – yet another symbol of the city's penchant for reinvention ushered in during Mayor Pete's tenure.  It might be seen as a reference to gold found in local streams or to its golden wheat fields, but to us Heartland Yellow represents innovation–different people coming together in the heart of America, building on our industrial heritage to bring new ideas to life for the 21st century economy.",
        image: heartlandStory
      },
      {
        color: ["clearSkies"],
        text: "Pete Buttigieg is unapologetically substantive yet salt-of-the-earth. He speaks plainly and intelligently about the everyday issues that matter to real everyday people. He is a rational thinker and a great communicator. Above all, he is a calming voice who can clearly explain how we can face the great challenges before us.",
        image: skyStory
      },
      {
        color: ["rustBelt","calm"],
        text: "Like many midwestern American cities, South Bend’s landscape is a window to its past. Its weathered red brick structures are a testament to the region’s industrial heritage, its contrasting bright skies a persistent symbol of optimism and readiness to adapt to the changing winds of history.",
        credit: <div>Photos by <a href="https://www.jacobtitus.co/" target="_blank" rel="noopener noreferrer">Jacob Titus</a></div>,
        image: rustStory
      },
      {
        color: ["buddy","truman"],
        text: <p><a href="https://twitter.com/firstdogssb" target="_blank" rel="noopener noreferrer">Buddy and Truman Buttigieg</a> are the first dogs of South Bend and an important part of Pete and his husband Chasten’s family. You can learn all about the lovable pooches on their lively twitter account.</p>,
        image: dogStory
      }
    ]
  },
  type: {
    slug: "type",
    heading: "Type",
    explainer: "Our brand typography pays homage to America’s industrial legacy, to Pete’s ability to convey substantive ideas plainly, and to his bold vision for a modern America that reaches for the future with focus and clarity.",
    items: [
      {
        name: "Aktiv Grotesk Ext.",
        className: "ff-aktiv",
        explainer: "The primary face is a 21st century interpretation of a Grotesque Sans font. The extended version of the font is timeless. The sleek lines feel equally modern today as they would have in an old Studebaker ad.",
        credit: "by Dalton Maag"
      },
      {
        name: "Industry",
        className: "ff-industry",
        explainer: "Industry is a square geometric font with clear roots in the visual language of American  manufacturing. This modern interpretation is remarkable in its range. Depending on how it's used it has different connotations: industrial, sporty, or military.",
        credit: "by Mattox Shuler from Fort Foundry"
      },
      {
        name: "Domaine Text",
        className: "ff-domaine",
        explainer: "Domaine Text acts as a sophisticated companion to the modern geometry of Aktiv and the stark boldness of Industry. This text font allows for easy readability of large bodies of copy.",
        credit: "by Klim Type Foundry"
      }
    ],
    styles: [
      {
        style: "label",
        ipsum: "Generational Change",
        className: "font-label",
        meta: {
          family: "Industry",
          capitalization: "All Caps",
          weight: "Bold (700)",
          size: "1.1x",
          lineHeight: "1",
          tracking: "1.2"
        }
      },
      {
        style: "h1",
        ipsum: "A Fresh Start for America",
        className: "font-hero-heading",
        meta: {
          family: "Aktiv Grotesk Ext.",
          capitalization: "All Caps",
          weight: "Bold (700)",
          size: "4x",
          lineHeight: "1.1",
          tracking: "1.3"
        },
      },
      {
        style: "h2",
        ipsum: "Freedom, Democracy, Security",
        className: "font-subheading",
        meta: {
          family: "Aktiv Grotesk Ext.",
          capitalization: "Sentence Case",
          weight: "Bold (700)",
          size: "1.3x",
          lineHeight: "1.1",
          tracking: "1.3"
        }
      },
      {
        style: "Intro",
        ipsum: "It's time for a new generation of American leadership in this country.",
        className: "font-intro",
        meta: {
          family: "Aktiv Grotesk Ext.",
          capitalization: "Sentence Case",
          weight: "Medium (500)",
          size: "1.3x",
          lineHeight: "1.1",
          tracking: "1.3"
        }
      },
      {
        style: "body",
        ipsum: "America is more than a broken Washington, D.C. and 2020 is about more than the next four years. Our country is changing, and what matters most is how we will rise to meet the challenges ahead in our economy, our society, and our politics.",
        className: "font-body",
        meta: {
          family: "Domaine Text",
          capitalization: "Sentence Case",
          weight: "Normal (400)",
          size: "1x",
          lineHeight: "1.67",
          tracking: null
        }
      }
    ],
    navCopy: "Type",
    // NavImage: Type,
    navBackgroundColor: "skagen"
  },
  pages: [],
  currentPage: null
  // places: {
  //   slug: "places",
  //   heading: "Places",
  //   explainer: <><p>Our grassroots movement needs powerful tools to be effective. Below you’ll find downloadable state graphics and interest group graphics. You are free to download them, print them out, add them to your social profiles and use them to spread the word about our campaign.</p>
  //     <p>Are there other graphics that would be useful? <a href="mailto:design@peteforamerica.com">Let us know.</a></p></>,
  //   navBackgroundColor: "rustBelt",
  //   navForegroundColor: "strato",
  //   navCopy: "Places",
  //   NavImage: Team
  // },
  // pride: {
  //   slug: "pride",
  //   heading: "Pride with Pete and Chasten",
  //   explainer: <><p>Use the downloadable graphics below to celebrate Pride with Pete and Chasten this year! We’re excited to see what you make. #prideforpete</p>
  //     <p>Tons of Pride gear is coming to the official store, too! Hit us up here to be the first to know when it launches! <a href="mailto:design@peteforamerica.com?subject=I want Pride gear!">Hit us up here</a> and we’ll give you a heads up when its all available.</p></>,
  //   navBackgroundColor: "claeys",
  //   navCopy: "Pride",
  //   NavImage: Pride
  // }
}

export default data
