import React from "react";
import { connect } from "react-redux";
import "./index.scss";
import { useTransition, animated } from "react-spring";
import { filterColors } from "helpers.js";
import transparentSwatch from "assets/transparent.svg";

const ColorPicker = ({ colors, selectedKey, setSelected, mode, enabled=[], logoItems }) => {
  if (mode === "text" && enabled.indexOf("transparent") > 0) {
    enabled.splice(enabled.indexOf("transparent"))
  }
  const logoIsTwotoned = logoItems[0].twotoned && mode === "logo";
  const filteredColors = filterColors(enabled,colors,logoIsTwotoned);

  const transitions = useTransition(filteredColors, d=>d.key, {
    from: {
      opacity: 0,
      width: "0px"
    },
    enter: {
      opacity: 1,
      width: "70px",
      height: "70px"
    },
    leave: {
      display: 'none'
    }
  });

  return (
    <div className="ColorPicker">
      {transitions.map(({ item, props, key }) => {
        const isSelected = selectedKey === item.key;
        return (
          <animated.div className={`
            ColorPicker-color
            ${isSelected ? "ColorPicker-color--selected" : ""}
          `.trim()} style={props} key={key}>
            <div className="ColorPicker-color-inner">
              <Color setSelected={setSelected} color={item} />
            </div>
          </animated.div>
        )
      })}
    </div>
  )
}

const mapStateToProps = state => ({
  logoItems: state.sandbox.logo
})
export default connect(mapStateToProps)(ColorPicker)

const Color = ({ color, setSelected }) => {
  const { keys, name, colors } = color

  function onClick() {
    setSelected(keys)
  }
  return (
    <button
      onClick={onClick}
      data-tip={name}>
      <div className="ColorPicker-color-swatchwrap">
      {colors.map(color => (
        color === 'transparent' ?
        <div key={color}
          style={{
            backgroundImage: `url(${transparentSwatch}`
          }}
          className={`ColorPicker-color-swatch ColorPicker-color-swatch--transparent`} /> :
        <div
          key={color}
          className="ColorPicker-color-swatch"
          style={{
            backgroundColor: color
          }} />
      ))}
      </div>
    </button>
  )
}
