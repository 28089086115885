import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setAssetModalOpen,
  setAssetModalAssetName,
  setAssetModalAssetMarkup,
  // setSandboxForegroundColor,
  // setSandboxBackgroundColor,
  setAssetModalAssetPrimaryColor,
  setAssetModalAssetBackgroundColor
} from "redux/actions";
import { ReactComponent as Down } from "assets/down.svg";
import DownloadSvg from "components/DownloadSvg";
import { styleMarkup } from "helpers.js";

function getColor(stack,needle) {
  return Object.values(stack).find(c => c.hex === needle);
}

const Asset = ({ id, title, assetUrl, authors, primaryColor, backgroundColor, secondaryColor, setDownloadAssetModal, colors, dynamicAsset, noPad }) => {
  const [svgText,setSvgText] = useState()

  useEffect(() => {
    fetch(assetUrl)
      .then(r=>r.text())
      .then(text => {
        setSvgText(text);
      })
  },[])

  const primary = getColor(colors,primaryColor)
  const background = getColor(colors,backgroundColor)
  const secondary = secondaryColor ? getColor(colors,secondaryColor) : null
  const defaultBg = '#eef4f8'

  let hoverMark = !primary && !background ? (
      <div className="hover-mark font-action"  style={{
        backgroundColor: '#000000',
        color: defaultBg
      }}>
        <Down stroke={defaultBg} />
      </div>
    ) : (
      <div className="hover-mark font-action"  style={{
        backgroundColor: !!primary ? primary.hex : null,
        color: !!background ? background.hex : null
      }}>
        <Down stroke={!!background ? background.hex : null} />
      </div>
    )



  return (
    <div className="Assets-item">
      {/* <div */}
      {/*   className="Assets-item-inner" */}
      {/*   onClick={() => { */}
      {/*   setDownloadAssetModal({ */}
      {/*     name: title, */}
      {/*     markup: svgText, */}
      {/*     color: primary.key, */}
      {/*     backgroundColor: background.key */}
      {/*   }) */}
      {/* }}> */}
        {/* <img src={assetUrl} alt="" /> */}
      {svgText && (
        <DownloadSvg
          name={title}
          logoMarkup={styleMarkup(svgText,title,primary,secondaryColor,background,dynamicAsset)}
          color={!!primary ? primary.key : null}
          backgroundColor={!!background ? background.key : null}
          secondaryColor={secondaryColor}
          twotoned={!!secondaryColor}
          dynamicAsset={dynamicAsset}>
          <div style={{ backgroundColor: background ? background.hex : defaultBg }} className={`Logo-image${noPad ? ' no-pad' : ''}`} dangerouslySetInnerHTML={{ __html: styleMarkup(svgText,title,primary,secondary,background,dynamicAsset) }} />
          { hoverMark }
        </DownloadSvg>
      )}
      {/* </div> */}
      <div className="Logo-title">
        {authors.length > 0 && <span>by {authors.map(author => author.website ?
          <a key={author.name}
            href={author.website}
            target="_blank"
            rel="noopener noreferrer">{author.name}</a> :
          <span key={author.name}>{author.name}</span>
        )}</span>}
        {authors.length < 1 ? <span>{title}</span> : ''}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  colors: state.data.color.items
})
const mapDispatchToProps = dispatch => ({
  setDownloadAssetModal: ({ name, markup, color, backgroundColor }) => {
    dispatch(setAssetModalAssetName(name))
    dispatch(setAssetModalAssetMarkup(markup))

    //dispatch(setSandboxForegroundColor(color))
    //dispatch(setSandboxSecondaryColor(values[1] || null))
    //dispatch(setSandboxBackgroundColor(backgroundColor))

    dispatch(setAssetModalAssetPrimaryColor(color))
    dispatch(setAssetModalAssetBackgroundColor(backgroundColor))
    dispatch(setAssetModalOpen(true))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(Asset);
