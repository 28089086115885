import React, { useEffect } from "react";
import "./index.scss";
import ColorCombo from "components/ColorCombo";
import LogoShowcase from "./LogoShowcase";
import { setSandboxMode } from "redux/actions";
import { connect } from "react-redux";

const Logos = ({ data, items, setSandboxMode }) => {
  useEffect(() => {
    setSandboxMode('logo')
  },[])

  return (
    <>
      <LogoShowcase items={items} />
      <ColorCombo />
    </>
  )
}

const mapStateToProps = state => ({
  data: state.data.logo
})
const mapDispatchToProps = dispatch => ({
  setSandboxMode: value => dispatch(setSandboxMode(value)),
})
export default connect(mapStateToProps,mapDispatchToProps)(Logos);
