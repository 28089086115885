import React from "react";
import './App.scss';

import { Provider, connect } from 'react-redux';
import store from "./redux/store.js";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactTooltip from 'react-tooltip'
import NavBar from "components/NavBar";
import ColorCombo from "components/ColorCombo";
import Section from "components/Section";
import AssetDownload from "components/AssetDownload";
import Home from "components/Home";
import AssetPage from "components/AssetPage";
import Assets from "components/AssetPage/Assets.js";
import SingleAsset from "components/AssetPage/SingleAsset.js";
import PhotoAlbum from "components/PhotoAlbum";

import { isMobile } from "helpers";

// import Logos from "components/Logos";
// import Colors from "components/Colors";
// import Type from "components/Type";
// import AnyPage from "components/Page";

const Root = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedApp />
      </BrowserRouter>
    </Provider>
  );
}

export default Root;

const App = ({ match, data }) => {
  if (!data.pages) {
    return "Loading"
  } else {
    return (
      <div className="App">
        <main className={`${isMobile ? 'touch' : ''}`}>
          <NavBar />
          <Switch>
            <Route exact path={"/"} component={Home} />
            <Route path={`/photography/:id`} component={PhotoAlbum} />
            <Route path={`/:slug/:childSlug`}
              render={({ match })=> (
                <Section>
                  <section className="Section-pad">
                    <Assets match={match} />
                  </section>
                </Section>
            )} />

            <Route path={`/:slug`} component={AssetPage} />
            <Route path={`/:slug'/:item'`} component={SingleAsset} />
            <Route
              path={`/sandbox`}
              render={({ match }) => (
                <Section className="in">
                  <ColorCombo showModes={true} />
                </Section>
              )} />
            <Route
              exact path={`/admin`}
              component={() => {
                window.location = "/api";
                return null
              }} />
          </Switch>
          <AssetDownload />
          <ReactTooltip
            className="Tooltip tooltip-label"
            effect="solid"
            // disable={ isMobile }
            clickable={ isMobile }
            />
        </main>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  data: state.data
})

const mapDispatchToProps = null

const ConnectedApp = connect(mapStateToProps,mapDispatchToProps)(App);

// const PrivateRoute = ({ authenticated=false, component: Component, ...rest }) => {
//   return <Route {...rest} render={props => {
//     return authenticated ? <Component {...props} /> :
//     <Redirect to="/login" />
//   }} />
// }
