import React from "react";
import Dropdown from 'react-select';

const ColorDropdown = ({ options, value, setValue }) => {
  return (
    <Dropdown
      value={value}
      options={options}
      onChange={selected => {
        setValue(selected)
      }} />
  );
}

export default ColorDropdown