export const setSandboxMode = (value) => ({
  type: "SET_SANDBOX_MODE",
  value
})
export const setSandboxLogo = (value) => ({
  type: "SET_SANDBOX_LOGO",
  value
})
export const setSandboxForegroundColor = (value) => ({
  type: "SET_SANDBOX_FOREGROUND_COLOR",
  value
})
export const setSandboxSecondaryColor = (value) => ({
  type: "SET_SANDBOX_SECONDARY_COLOR",
  value
})
export const setSandboxBackgroundColor = (value) => ({
  type: "SET_SANDBOX_BACKGROUND_COLOR",
  value
})

export const setAssetModalOpen = (value) => ({
  type: "SET_ASSET_MODAL_OPEN",
  value
})

export const setAssetModalAssetName = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_NAME",
  value
})

export const setAssetModalAssetMarkup = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_MARKUP",
  value
})

export const setAssetModalAssetIsTwotoned = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_IS_TWOTONED",
  value
})

export const setAssetModalAssetPrimaryColor = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_PRIMARY_COLOR",
  value
})

export const setAssetModalAssetSecondaryColor = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_SECONDARY_COLOR",
  value
})

export const setAssetModalAssetBackgroundColor = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_BACKGROUND_COLOR",
  value
})

export const setAssetModalAssetDynamic = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_DYNAMIC",
  value
})

export const setAuthenticated = (value) => ({
  type: "SET_AUTHENTICATED",
  value
})

export const setAssetTermsAccepted = (value) => ({
  type: "SET_ASSET_TERMS_ACCEPTED",
  value
})

export const setNavbarStyle = (value) => ({
  type: "SET_NAVBAR_STYLE",
  value
})

export const setPageData = (value) => ({
  type: "SET_PAGE_DATA",
  value
})

export const setPhotoData = (value) => ({
  type: "SET_PHOTO_DATA",
  value
})

export const getPageData = (value) => ({
  type: "GET_PAGE_DATA",
  value
})

export const requestPageData = (value) => ({
  type: "REQUEST_PAGE_DATA",
  value
})

const fetchPages = (url) => {
  return dispatch => {
    dispatch(requestPageData(url))
    return fetch(`${process.env.REACT_APP_API}/api/${url}.json`)
      .then(response => response.json())
      .then(json => {
        const pages = [].concat.apply([], json);
        dispatch(getPageData(pages))
      })
  }
}

const shouldFetchPages = (state) => {
  const pages = state.data.pages
  if (!pages) {
    return false
  } else {
    return true
  }
}

export const fetchPagesIfNeeded = (url) => {
  return (dispatch, getState) => {
    if (shouldFetchPages(getState(), url)) {
      return dispatch(fetchPages(url))
    } else {
      return Promise.resolve()
    }
  }
}
