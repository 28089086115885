export default (svg_xml,size) => {
  return new Promise((resolve,reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext('2d');
    context.imageSmoothingQuality = "high"
    canvas.width = size.width
    canvas.height = size.height

    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svg_xml);

    img.onload = function() {
      context.clearRect(0,0,canvas.width,canvas.height);
      context.drawImage(img, 0, 0, canvas.width, canvas.height);

      resolve({
        png: canvas.toDataURL('image/png'),
        jpg: canvas.toDataURL('image/jpeg', 1.0)
      })
    }
  })
}
