import React from "react";
import { Link } from "react-router-dom";
import Image from "components/Image";

export default ({ id, url, title, albumCover }) => {
  let Img
  if (albumCover && albumCover.srcset) {
    Img = <Image src={albumCover.src} srcset={albumCover.srcset} title={albumCover.title} />
  } else if (albumCover) {
    Img = <img src={albumCover.downloadUrl} alt={albumCover.title} />
  } else {
    Img = ''
  }

  return (
    <Link
      to={url}
      title={title}
      style={{ display: 'block', textAlign: 'center' }}>
      {Img}
      <span className="font-body">{ title }</span>
    </Link>
  )
}
