import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import "./index.scss";
import tinycolor from "tinycolor2";

export default ({data, ...rest}) => {
  const navGrid = data.pages.length%3 === 0 ? 'Nav-grid--3' : 'Nav-grid--4'
  return (
    // TODO - when Craft 3 includes conditionals,
    // we'll remove Simple Nav Item and fix on back end
    <nav {...rest} className={`Nav ${navGrid}`} style={{ backgroundColor: '#f2ba42'}}>
      {data.pages.map(d => (d.navTile.length || d.linkType == "externalLink") ?
        <NavItem key={d.id} data={d} {...d} /> :
        <SimpleNavItem key={d.id} data={d} {...d} />
      )}
    </nav>
  )
}

export const TextNav = ({ data, ...rest }) => {
  return (
    <nav {...rest} className="TextNav">
      {data.pages.map(d => (
        d.linkType == "externalLink" ?
          <a className="TextNav-link font-heading"
          href={d.website}
          key={d.id}
          target="_blank">{d.title}</a>
        : <Link className="TextNav-link font-heading"
          key={d.id}
          to={`/${d.slug}`}>
          {d.navTile.length && d.navTile[0].navTitle ? d.navTile[0].navTitle : d.title}
        </Link>)
      )}
      <a className="TextNav-link font-heading"
        href="mailto:design@peteforamerica.com"
        rel="noopener noreferrer">
        Contact Us
      </a>
      <div className="TextNav-label font-label"
        style={{ padding: "1em" }}>
        <span>Design by </span>
        <a href="https://www.hyperakt.com" target="_blank" rel="noopener noreferrer">
          Hyperakt
        </a>
      </div>
    </nav>
  )
};

const SimpleNavItem = ({ data }) => {
  const [hovered,setHovered] = useState(false)

  function onMouseEnter(e) {
    setHovered(true)
  }
  function onMouseLeave() {
    setHovered(false)
  }

  const { title } = data
  const transition = useTransition(hovered,null,{
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    unique: true
  })

  if (data.linkType == 'externalLink') {
    return <a href={`/${data.website}`} target="_blank">
      <div
        className="Nav-item"
        onPointerEnter={onMouseEnter}
        onPointerLeave={onMouseLeave}
        >
        {transition.map(({ item, props, key }) => item ?
          <animated.div key={"text"} style={{
            ...props,
          }} className="font-heading">{title}</animated.div> : ''
        )}
      </div>
    </a>
  } else {
    return <Link to={`/${data.slug}`}>
      <div
        className="Nav-item"
        onPointerEnter={onMouseEnter}
        onPointerLeave={onMouseLeave}
        >
        {transition.map(({ item, props, key }) => item ?
          <animated.div key={"text"} style={{
            ...props,
          }} className="font-heading">{title}</animated.div> : ''
        )}
      </div>
    </Link>
  }

}

const NavItem = ({ data }) => {
  const [hovered,setHovered] = useState(false)
  const [focused,setFocused] = useState(false)

  function onMouseEnter(e) {
    setHovered(true)
  }
  function onMouseLeave() {
    setHovered(false)
  }

  const { title, navTile, linkType } = data
  const { backgroundColor, foregroundColor, navTitle, backgroundImage } = linkType == "externalLink" ? navTile : navTile[0]
  let NavImage

  if (backgroundImage && backgroundImage.indexOf(".svg") > -1) {
    const [svgText,setSvgText] = useState()
    useEffect(() => {
      fetch(backgroundImage)
        .then(r=>r.text())
        .then(text => {
          setSvgText(text);
        })
    },[])

    NavImage = svgText ? <div dangerouslySetInnerHTML={{ __html: svgText }} /> : title
  } else {
    NavImage = backgroundImage ? <div className="Nav-item__image" style={{ backgroundImage: `url(${backgroundImage})` }} /> : title
  }

  const transition = useTransition(focused || hovered,null,{
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    unique: true
  })

  if (linkType == "externalLink") {
    return (
      <a href={data.website}
        target="_blank"
        onPointerEnter={onMouseEnter}
        onPointerLeave={onMouseLeave}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        target="_blank"
        >
        <div
          className="Nav-item"
          style={{
            backgroundColor: backgroundColor ? backgroundColor : '',
            fill: foregroundColor ? foregroundColor : ''
          }}>
          {transition.map(({ item, props, key }) => item ?
            <animated.div key={"text"} style={{
              ...props,
              color: backgroundColor ? (tinycolor(backgroundColor).isLight() ? "#000" : "#fff") : ''
            }} className="font-heading">{navTitle}</animated.div> :
            <animated.div key={"image"} style={props}>
              {NavImage}
            </animated.div>
          )}
        </div>
      </a>
    )
  } else {
    return (
      <Link to={`/${data.slug}`}
        onPointerEnter={onMouseEnter}
        onPointerLeave={onMouseLeave}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        >
        <div
          className="Nav-item"

          style={{
            backgroundColor: backgroundColor ? backgroundColor : '',
            fill: foregroundColor ? foregroundColor : ''
          }}>
          {transition.map(({ item, props, key }) => item ?
            <animated.div key={"text"} style={{
              ...props,
              color: backgroundColor ? (tinycolor(backgroundColor).isLight() ? "#000" : "#fff") : ''
            }} className="font-heading">{navTitle ? navTitle : title}</animated.div> :
            <animated.div key={"image"} style={props}>
              {NavImage}
            </animated.div>
          )}
        </div>
      </Link>
    )
  }

}
