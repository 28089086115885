import React from "react";
import { connect } from "react-redux";
import {
  setAssetModalOpen,
  setAssetModalAssetName,
  setAssetModalAssetMarkup,
  setAssetModalAssetIsTwotoned,
  setAssetModalAssetPrimaryColor,
  setAssetModalAssetSecondaryColor,
  setAssetModalAssetBackgroundColor,
  setAssetModalAssetDynamic
} from "redux/actions";

const Download = ({ name, logoMarkup, color, secondaryColor, backgroundColor, twotoned, children, setDownloadAssetModal, dynamicAsset }) => {
  return (
    <div className="LogoColors-download" onClick={() => {
      setDownloadAssetModal({
        name: name,
        markup: logoMarkup,
        color: color,
        secondaryColor: secondaryColor,
        backgroundColor: backgroundColor,
        twotoned: twotoned,
        dynamicAsset: dynamicAsset
      })
    }}>
      {children}
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  setDownloadAssetModal: ({ name, markup, color, secondaryColor, backgroundColor, twotoned, dynamicAsset }) => {
    dispatch(setAssetModalAssetName(name))
    dispatch(setAssetModalAssetMarkup(markup))
    dispatch(setAssetModalAssetIsTwotoned(twotoned))
    dispatch(setAssetModalAssetPrimaryColor(color))
    dispatch(setAssetModalAssetSecondaryColor(secondaryColor))
    dispatch(setAssetModalAssetBackgroundColor(backgroundColor))
    dispatch(setAssetModalAssetDynamic(dynamicAsset))
    dispatch(setAssetModalOpen(true))
  }
})
export default connect(null,mapDispatchToProps)(Download);
