import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { loadState, saveState } from "./localStorage";
import throttle from "lodash/throttle"
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { fetchPagesIfNeeded } from "./actions";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();
const loggerMiddleware = createLogger();

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      // loggerMiddleware,
    )
  )
)

store.subscribe(throttle(() => {
  saveState({
    auth: store.getState().auth
  })
}), 1000)

store
  .dispatch(fetchPagesIfNeeded('navLinks'))
  // .then(() => console.log(store.getState()))

export default store;
