import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import convert from "color-convert";
import "./index.scss";
import Section from "components/Section";
import CopyToClipboard from "components/CopyToClipboard";
import tinycolor from "tinycolor2";

import ReactCompareImage from 'react-compare-image';

import colorCollage from "assets/colorstory/PeteMoodboard.jpg";
import colorCollageVertical from "assets/colorstory/PeteMoodboardVertical.jpg"
import colorCollageAbstract from "assets/colorstory/PeteMoodboardAbstract.jpg";
import colorCollageAbstractVertical from "assets/colorstory/PeteMoodboardAbstractVertical.jpg";

const Colors = ({ data }) => {
  const [useVertical,setUseVertical] = useState()
  useEffect(() => {
    window.addEventListener("resize",onResize)
    onResize()
    return () => {
      window.removeEventListener("resize",onResize)
    }
  },[])

  function onResize() {
    const newUseVertical = window.innerWidth < 500;

    if (newUseVertical !== useVertical) {
      setUseVertical(newUseVertical);
    }
  }

  return (
    <Section>
      <div className="Colors">
        {Object.values(data.items)
          .filter(d => !d.hidden)
          .map(color => <Color key={color.hex} {...color} />)}
      </div>
      <Section heading={"Color Story"}>
        <div className="ColorStoryCompare">
          <ReactCompareImage
            hover
            sliderLineColor={data.items.strato.hex}
            leftImage={useVertical ? colorCollageVertical : colorCollage}
            rightImage={useVertical ? colorCollageAbstractVertical : colorCollageAbstract} />
        </div>
        <ColorStories items={data.colorStories} data={data.items} />
      </Section>
    </Section>
  )
}

const mapStateToProps = state => ({
  data: state.data.color
})
const mapDispatchToProps = null
export default connect(mapStateToProps,mapDispatchToProps)(Colors);

const Color = ({ name, hex, cmyk, pms, role }) => {
  return (
    <div className={`Color Color--${role}`}>
      <div className="Color-inner">
        <CopyToClipboard text={hex.replace('#','')}>
          {(copied) => (
          <div className="Color-swatch" style={{ backgroundColor: hex }}>
            <div
              className="hover-mark font-action"
              style={{
                color: tinycolor(hex).isLight() ? "inherit" : "#fff"
              }}>
              {copied ? "Hex Copied" : "Copy Hex"}
            </div>
          </div>)}
        </CopyToClipboard>
        <div className="Color-swatch-meta">
          <div className="Color-label font-label">{name}</div>
          <div className="Color-values">
            <ColorValue label="hex" value={hex} />
            <ColorValue label="rgb" value={convert.hex.rgb(hex).join(", ")} />
            <ColorValue label="cmyk" value={(cmyk || convert.hex.cmyk(hex)).join(", ")} />
            <ColorValue label="pms" value={pms} />
          </div>
        </div>
      </div>
    </div>
  )
}

const ColorValue = ({ label, value }) => (
  <div className="Color-value">
    <span className="Color-value-label font-sublabel">{label}</span>
    <span className="font-value">{value}</span>
  </div>
)

const ColorStories = ({
  items,
  data
}) => (
  <ul className="ColorStories">
    {items.map(item => (
      <ColorStoriesItem
        key={item.color.join('')}
        heading={item.color.reduce((r,d,i) => {
          const name = data[d].name
          if (i === 0) {
            r = name
          } else if (i === item.color.length - 1) {
            r += " and " + name
          } else {
            r += ", " + name
          }

          return r
        },"")}
        text={item.text}
        image={item.image}
        credit={item.credit} />
    ))}
  </ul>
)

const ColorStoriesItem = ({
  heading,
  image,
  text,
  credit
}) => (
  <li className="ColorStories-item">
    <div className="ColorStories-item-image"><img src={image} alt="" /></div>
    <div className="ColorStories-item-heading font-subheading">{heading}</div>
    <div className="ColorStories-item-text font-body">{text}</div>
    {credit && <div className="ColorStories-item-credit font-credit">{credit}</div>}
  </li>
)
