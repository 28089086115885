import React, { useState } from "react";
import "./index.scss";

const isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);

const CopyToClipboard = ({ text="", children, className="" }) => {
  const [copied,setCopied] = useState(false)

  function onClick() {
    copyTextToClipboard(text).then((successful) => {
      setCopied(successful)
      console.log('copied: ' + copied)
    })
  }

  function onMouseLeave() {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 500)
    }
  }

  return (
    <button
      style={{ pointerEvents: isIOS ? 'none' : '' }}
      className={`CopyToClipboard ${className}`}
      onClick={onClick}
      onPointerLeave={onMouseLeave}>
      {children(copied)}
    </button>
  )
}

export default CopyToClipboard;

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  //ios?
//   textArea.contentEditable = true;
//   textArea.readOnly = false;
//
//   var range = document.createRange();
//   range.selectNodeContents(textArea);
//   var s = window.getSelection();
//   s.removeAllRanges();
//   s.addRange(range);
//   textArea.setSelectionRange(0, 999999);
  //document.body.appendChild(textArea);

  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
    //document.body.removeChild(textArea);
    return successful;
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
    //document.body.removeChild(textArea);
    return false
  }
}


function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      resolve(fallbackCopyTextToClipboard(text));
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
      resolve(true)
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
      resolve(false)
    });
  })

}