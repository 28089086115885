import React, { useEffect, useState } from "react";
import Asset from "./Asset.js";
import Assets from "./Assets.js";
import Authors from "./Authors.js";
import NgpForm from "./NgpForm.js";
import "./index.scss";
import Section from "components/Section";
import Type from "components/Type";
import Colors from "components/Colors";
import Logos from "components/Logos";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";
import SetNavbarStyle from "components/SetNavbarStyle";
import AlbumLink from "components/PhotoAlbum/AlbumLink";
import Image from "components/Image";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { setPageData } from "redux/actions";

const AssetPage = ({ match }) => {
  const [data,setData] = useState()
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/api/page/${match.params.slug}.json`)
      .then(response => response.json())
      .then(response => {
        setData(response)
        // setPageData(response.slug)
      })
  },[match])

  if (data) {
    const { navStyle, pageSections } = data;

    return (<section className={`Section-pad ${match.params.slug}`}>
      <ScrollToTopOnMount routeChange={match} />
      <SetNavbarStyle theme={navStyle.value} navLogoVisible={true} />
        {
          pageSections.map((section) => {
            switch (section.sectionType) {
              case "pageSection":
                return <Section className="in"
                  key={ section.id }
                  heading={ section.sectionTitle }
                  explainer={ section.copy }
                  inlineAsset={ section.inlineAsset }
                  downloadableAsset={ section.downloadableAsset }
                  fontStyle={ section.copyStyle }
                />
              case "assetSection":
                return <Section className="in" key={ section.id }>
                  <Assets
                    assetTypes={ section.assetTypes }
                    assets={ section.assets }
                    heading={ section.sectionTitle }
                    subtitle={ section.sectionSubtitle }
                    noPad={ section.noPad }
                   />
                 </Section>
              case "assetCategorySection":
                return <Section className="in" key={ section.id }>
                  <h2 className="Asset-type-title font-subheading">{section.sectionTitle}</h2>
                  {section.sectionSubtitle ? <p className="Asset-type-subtitle font-body">{section.sectionSubtitle}</p> : null}
                  <ul className="Grid Grid-module--3">
                    {section.assetTiles.map(assetType =>
                      <li key={ assetType.id }>
                        <AlbumLink url={ `/${match.params.slug}/${assetType.id}` } title={ assetType.title } albumCover={ assetType.albumCover } />
                      </li>
                    )}
                  </ul>
                </Section>
              case "categorySection":
                return <Section className="in" key={ section.id }>
                  <Authors items={ section.authors } />
                </Section>
              case "moduleSection":
                return <Section className="in" key={ section.id }>
                  <ul className="Grid Grid-module--4">
                    {section.photoAlbums.map(album =>
                      <li key={ album.id } style={{ padding: '0 1rem' }}>
                        <AlbumLink url={ `/photography/${album.slug}` } title={ album.title } albumCover={ album.albumCover } {...album} />
                      </li>
                    )}
                  </ul>
                </Section>
              case "ngpEmbed":
                return <Section className="in" key={ section.id }>
                  <NgpForm formUrl={section.formURL}/>
                </Section>
              case "fullBleedImage":
                return <figure key={ section.id } className="PhotoSection fullWidth">
                  <Image src={section.image.src} srcset={section.image.srcset} title={section.image.title} />
                </figure>
              case "sideBySideImages":
                return <figure key={ section.id } className="PhotoSection halfWidth">
                  {section.images.map(image =>
                    <Image key={image.id} src={image.src} srcset={image.srcset} title={image.title} style={{width: '50%'}} />
                  )}
                </figure>
              default:
                return null
              }
          })
        }
        { data.slug === "type" ? <Type /> : null }
        { data.slug === "colors" ? <Colors /> : null }
    </section>)
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  currentPage: state.data.currentPage
})

const mapDispatchToProps = dispatch => ({
  setPageData: (value) => {
    dispatch(setPageData(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(AssetPage);
