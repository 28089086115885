import React from "react";
import { ReactComponent as Down } from "assets/down.svg";

export const Formats = ({ downloadUrls, downloadName }) => {
  return downloadUrls ? (
      <div className="AssetDownload-controls-format AssetDownload-controls-control--radio font-label">
        <DownloadFormat href={downloadUrls['svg']} name={downloadName+'.svg'}>SVG</DownloadFormat>
        <DownloadFormat href={downloadUrls['jpg']} name={downloadName+'.jpg'}>JPG</DownloadFormat>
        <DownloadFormat href={downloadUrls['png']} name={downloadName+'.png'}>PNG</DownloadFormat>
      </div>
  ) : "Loading"
}

export const DownloadFormat = ({
  name,
  href,
  children,
  termsAccepted,
  acceptTerms
}) => {
  const dlEvent = downloadEvent(name)
  return (
    <a href={href}
      className="AssetDownload-link"
      download={name}
      onClick={(event) => {
        dlEvent()
      }}
      >
      {children}
      <Down />
    </a>
  )
}

function downloadEvent(label) {
  return () => {
    if (window.gtag) {
      window.gtag('event', 'download', {
        'event_category': 'graphic',
        'event_label': label
      })
    } else {
      console.log('no gtag!')
    }
  }
}
