import React, { useState, useEffect, useRef } from "react";
//import Modal from "react-modal";
import { useSpring, animated } from "react-spring";
import { connect } from "react-redux";
import { setAssetModalOpen } from "redux/actions";

import data from "data.js";
import "./index.scss";
import { ReactComponent as X } from "assets/x.svg";
import convert from "color-convert";
import { Sizes } from "./Sizes.js";
import { Formats } from "./Formats.js";
import { round } from "./helpers.js";
import svgToImage from "./svgToImage.js";
import Dropdown from 'react-select';
import 'react-dropdown/style.css';
import ColorDropdown from "components/ColorDropdown";
import { filterColors, styleTag, removeBgRect } from "helpers.js";
import {
  setAssetModalAssetPrimaryColor,
  setAssetModalAssetSecondaryColor,
  setAssetModalAssetBackgroundColor
} from "redux/actions";

import ConfirmAssetTerms from "./ConfirmAssetTerms.js";
import Contrast from "components/Contrast";

//Modal.setAppElement("#root")
const MIN_WIDTH = 2000
let sizeOptions = [
  {
    value: null,
    label: "Custom"
  },
  {
    value: [2000,2000],
    label: "Square"
  },
  {
    value: [5120,2880],
    label: "Desktop"
  },
  {
    value: [1080,1920],
    label: "Mobile"
  },
  {
    value: [4500,1500],
    label: "Banner"
  },
  {
    value: [5400,3600],
    label: "Rally Sign"
  }
]
const formatOption = ({
  value,
  label
}) => ({
  value,
  label: `${label}${Array.isArray(value) ? ` (${value[0]}x${value[1]})` : ''}`
})
sizeOptions = sizeOptions.map(formatOption)

const AssetDownload = ({
  isOpen,
  onRequestClose,
  assetName,
  assetMarkup,
  assetIsTwoToned,
  colors,
  foregroundColor,
  secondaryColor,
  backgroundColor,
  setPrimaryColor,
  setBackgroundColor,
  dynamicAsset
}) => {
  const canvasRef = useRef()
  const canvasWrapRef = useRef()
  const [size,setSize] = useState({
    width: 1,
    height: 1,
    aspectRatio: 1,
    ratioLocked: false
  })
  const [sizeDropdown,setSizeDropdown] = useState(sizeOptions[0])
  const [originalSize,setOriginalSize] = useState(size)
  const [downloadUrls,setDownloadUrls] = useState(null)
  const [svgError,setSvgError] = useState(false)

  if (!foregroundColor) foregroundColor = colors['strato']
  if (!backgroundColor) backgroundColor = colors['clearSkies']

  useEffect(() => {
    if (assetMarkup) {
      const viewBox = assetMarkup.match(/viewBox="([.\d]*) ([.\d]*) ([.\d]*) ([.\d]*)"/);
      const aspectRatio = viewBox[3]/viewBox[4]
      let width = +viewBox[3];
      let height = +viewBox[4];

      setOriginalSize({
        width,
        height,
        aspectRatio
      })

      if (width < MIN_WIDTH) {
        width = MIN_WIDTH
        height = width/aspectRatio
      }

      setSize({
        width,
        height,
        aspectRatio
      })
    }
  }, [assetMarkup])

  useEffect(() => {
    if (sizeDropdown.value) {
      if (size.width !== sizeDropdown.value[0] || size.height !== sizeDropdown.value[1]) {
        setSize({
          ...size,
          width: +sizeDropdown.value[0],
          height: +sizeDropdown.value[1]
        })
      }
    }
  },[sizeDropdown.value])

  useEffect(() => {
    if (size.ratioLocked) {
      setSize({
        ...size,
        height: size.width/size.aspectRatio
      })
    } else {
      setSize({
        ...size,
        aspectRatio: size.width/size.height
      })
    }
  }, [size.width])

  useEffect(() => {
    if (size.ratioLocked) {
      setSize({
        ...size,
        width: size.height*size.aspectRatio
      })
    } else {
      setSize({
        ...size,
        aspectRatio: size.width/size.height
      })
    }
  }, [size.height])

  useEffect(() => {
    const preset = sizeOptions.find(d => {
      return d.value && d.value[0] === size.width && d.value[1] === size.height;
    })
    if (preset) {
      setSizeDropdown(preset);
      return
    }

    if (sizeDropdown.value && (size.width !== sizeDropdown.value[0] || size.height !== sizeDropdown.value[1])) {
      setSizeDropdown(sizeOptions[0])
    }
  },[size.width,size.height])

  useEffect(()=> {
    const dpr = window.devicePixelRatio || 1;
    const canvas = canvasRef.current;

    if (canvas) {
      const context = canvas.getContext('2d');
      context.imageSmoothingQuality = "high";

      const [cleanedMarkup] = removeBgRect(assetMarkup);
      const splitMarkup = cleanedMarkup.match(
        /([^]*<svg)([^]*viewBox=")([.\d]*) ([.\d]*) ([.\d]*) ([.\d]*)("[^]*?>[^]*?)(\s*?<style>[^]*<\/style>\s*?)?([^]*<\/svg>)/
      );

      let width;
      let height;
      let xOff = 0
      let yOff = 0
      if (size.aspectRatio > originalSize.aspectRatio) {
        //wider
        width = originalSize.height*size.aspectRatio;
        height = +originalSize.height
        xOff = width - originalSize.width
      } else if (size.aspectRatio < originalSize.aspectRatio) {
        //taller
        width = +originalSize.width
        height = originalSize.width/size.aspectRatio
        yOff = height - originalSize.height
      } else {
        //equal
        width = +originalSize.width
        height = +originalSize.height
      }

      if (size.aspectRatio >= 1) {
        const canvasWidth = canvasWrapRef.current.getBoundingClientRect().width;
        canvas.width = canvasWidth * dpr;
        canvas.height = (canvasWidth/size.aspectRatio) * dpr;
      } else {
        const canvasHeight = canvas.getBoundingClientRect().height;
        canvas.width = (canvasHeight*size.aspectRatio) * dpr;
        canvas.height = canvasHeight * dpr;
      }

      let markup = [
        splitMarkup[1],
        ` width="${width}" height="${height}" `,
        splitMarkup[2],
        splitMarkup[3] - xOff/2,
        ' ',
        splitMarkup[4] - yOff/2,
        ' ',
        width,
        ' ',
        height,
        splitMarkup[7]
      ]

      if (dynamicAsset) {
        let colorMarkup = [
          styleTag(foregroundColor,secondaryColor,backgroundColor,assetName.replace(/\s/g,"")),
          backgroundColor.key !== "transparent" ? `<rect
            id="bg"
            x="${-xOff/2}"
            y="${-yOff/2}"
            width="${width}"
            height="${height}" />
          `.trim() : "",
          splitMarkup[9]
        ]

        markup.push(colorMarkup)
        markup = markup.join('').replace(/(fill="#[\d\w]{3,6}")/g,``)
      } else {
        let colorMarkup = [
          styleTag('','',backgroundColor,assetName.replace(/\s/g,"")),
          backgroundColor.key !== "transparent" ? `<rect
            id="bg"
            x="${-xOff/2}"
            y="${-yOff/2}"
            width="${width}"
            height="${height}"
            fill="#eef4f8" />
          `.trim() : "",
          splitMarkup[9]
        ]
        markup.push(colorMarkup)
        markup = markup.join('')
      }

      // setColorOptions

      const img = new Image();
      img.src = "data:image/svg+xml;base64," + btoa(markup);
      img.onload = function() {
        context.clearRect(0,0,canvas.width,canvas.height);
        context.drawImage(img, 0, 0, canvas.width, canvas.height);

        svgToImage(markup,size).then((urls) => {
          setDownloadUrls({
            ...urls,
            svg: `data:image/svg+xml,${encodeURIComponent(markup)}`
          })
        })
      }

      img.onerror = function(e){
        setSvgError(true)
      }
    }
  }, [
    canvasRef.current,
    assetMarkup,
    originalSize.width,
    originalSize.height,
    size.width,
    size.height,
    size.aspectRatio,
    dynamicAsset ? foregroundColor.key : null,
    secondaryColor,
    dynamicAsset ? backgroundColor.key : null
  ])

  useEffect(() => {
    if (foregroundIsntApproved(foregroundColor.key,backgroundColor.foregrounds)) {
      const newForeground = backgroundColor.foregrounds[0]
      setPrimaryColor(Array.isArray(newForeground) ? newForeground : [newForeground])
    }
  },[backgroundColor.key])

  function foregroundIsntApproved(foreground,foregrounds) {
    return foregrounds.reduce((r,d) => {
      if ((Array.isArray(d) && foreground === d[0]) || foreground === d) {
        r++
      }
      return r;
    },0) === 0
  }

  //Colors
  const backgroundOptions = Object.values(colors).map(color => ({
    value: color.key,
    label: color.name
  }))

  const filteredColors = filterColors(backgroundColor.foregrounds,colors,assetIsTwoToned);

  const foregroundOptions = filteredColors.map(color => {
    return {
      value: color.key,
      label: color.name
    }
  })

  const [backgroundColorValue,setBackgroundColorValue] = useState()
  const [foregroundColorValue,setForegroundColorValue] = useState()
  useEffect(() => {
    setBackgroundColorValue(backgroundOptions.find(d => d.value === backgroundColor.key))
    const fg = foregroundOptions.find(d => d.value === foregroundColor.key + (secondaryColor ? secondaryColor.key : ""))
    setForegroundColorValue(fg)
  },[foregroundColor.key,secondaryColor,backgroundColor.key])

  //End Colors

  if (!assetMarkup) {
    return null
  }

  const downloadName = assetName;
  const bgRgb = convert.hex.rgb(data.color.items.heartland.hex);
  bgRgb.push(.9);

  const colorValueMap = [
    ...filterColors(Object.keys(colors),colors,assetIsTwoToned),
    ...filteredColors
  ].reduce((r,d) => {
    r[d.key] = d.keys
    return r
  }, {})

  return <>
    <Modal
      backgroundColor={bgRgb.join(',')}
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      <ConfirmAssetTerms onNo={onRequestClose}>
        { svgError ? <div className="AssetDownload"><p>Oops! Looks like something went wrong... <a href="mailto:design@peteforamerica.com" title="email us">get in touch</a> with us to get this asset.</p></div>
        : <div className="AssetDownload ff-industry" onClick={(e) => {
            e.stopPropagation()
          }}>
          {assetMarkup && <div className="AssetDownload-preview" ref={canvasWrapRef}>
            <div className="AssetDownload-preview-imagewrap">
              {/* <div dangerouslySetInnerHTML={{ __html: displayMarkup }} /> */}
              <canvas className="AssetDownload-preview-image" ref={canvasRef} />
              <div className="AssetDownload-preview-width">
                <div>{round(size.width)}px</div>
              </div>
              <div className="AssetDownload-preview-height" style={{
                width: `calc(${100/size.aspectRatio}% - ${3/size.aspectRatio}rem)`,
                top: `${(1.5/size.aspectRatio)/2}rem`
              }}>
                <div>{round(size.height)}px</div>
              </div>
            </div>
          </div>}
          <div className="AssetDownload-controls">
            <dl className="AssetDownload-controls-meta ff-aktiv">
              <dt className="font-control-label">Size</dt>
              <dd>
                <Dropdown value={sizeDropdown} options={sizeOptions} onChange={selected => {
                  setSizeDropdown(selected);
                }} />
                <Sizes size={size} setSize={setSize} />
                {/* <button onClick={() => { */}
                {/*   setSize({ */}
                {/*     ...size, */}
                {/*     ratioLocked: !size.ratioLocked */}
                {/*   }) */}
                {/* }}>{size.ratioLocked ? 'locked' : 'freeform'}</button> */}
              </dd>
              {dynamicAsset ?
              <div className="AssetDownload-controls-group AssetDownload-controls-colors">
              <dt className="font-control-label">Background</dt>
              <dd>
                {backgroundColorValue && <ColorDropdown
                  options={backgroundOptions}
                  value={backgroundColorValue}
                  setValue={(option) => {
                    setBackgroundColor(colorValueMap[option.value])
                  }} />}
              </dd>
              <dt className="font-control-label">Foreground</dt>
              <dd>
                <ColorDropdown
                  options={foregroundOptions}
                  value={foregroundColorValue}
                  setValue={(option) => {
                    const values = colorValueMap[option.value];
                    setPrimaryColor(values)
                  }} />
              </dd>
              </div>
              : ''}
              {dynamicAsset ? <Contrast colors={[backgroundColor.hex,foregroundColor.hex]} /> : ''}
            </dl>
            <Formats downloadUrls={downloadUrls} downloadName={downloadName} />
          </div>
        </div> }
      </ConfirmAssetTerms>
    </Modal>
    {isOpen && <button
      className="AssetDownload-close"
      onClick={onRequestClose}>
      <X />
    </button>}
  </>
}

const Modal = ({ isOpen, onRequestClose, backgroundColor, children }) => {
  const [style,setStyle] = useSpring(() => ({
    transform: 'translateY(100%)'
  }))

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : ''
  },[isOpen])

  setStyle({
    transform: isOpen ? 'translateY(0%)' : 'translateY(100%)'
  })

  return <animated.div
    className="AssetModal"
    style={{
      ...style,
      backgroundColor: `rgba(${backgroundColor})`,
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      justifyContent: 'center',
      height: '100vh',
      right: 0,
      overflowY: 'auto',
      overflowScrolling: "touch",
      WebkitOverflowScrolling: "touch",
      width: '100%',
      top: '0',
      zIndex: 2
    }}
    onClick={() => {
      onRequestClose()
    }}>
    <div
      className="AssetModal-inner"
      style={{
        display: 'flex'
      }}
      onClick={(e) => {
        onRequestClose()
      }}>
      {children}
    </div>
  </animated.div>
}

const mapStateToProps = state => {
  const colors = state.data.color.items;
  return {
    isOpen: state.assetDownload.modalIsOpen,
    assetName: state.assetDownload.assetName,
    assetMarkup: state.assetDownload.assetMarkup,
    assetIsTwoToned: state.assetDownload.assetIsTwotoned,
    dynamicAsset: state.assetDownload.dynamicAsset,
    foregroundColor: colors[state.assetDownload.primaryColor],
    secondaryColor: colors[state.assetDownload.secondaryColor],
    backgroundColor: colors[state.assetDownload.backgroundColor],
    colors
  }
}

const mapDispatchToProps = dispatch => ({
  onRequestClose: () => {
    dispatch(setAssetModalOpen(false))
  },
  setPrimaryColor: values => {
    dispatch(setAssetModalAssetPrimaryColor(values[0]))
    dispatch(setAssetModalAssetSecondaryColor(values[1] || null))
  },
  setBackgroundColor: value => {
    dispatch(setAssetModalAssetBackgroundColor(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(AssetDownload)
