import data from "data.js";
const defaultState = {
  mode: 'logo',
  logo: [data.logo.items[0]],
  foregroundColor: 'claeys',
  secondaryColor: null,
  backgroundColor: 'strato'
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SET_SANDBOX_MODE":
      return {
        ...state,
        mode: action.value
      }

    case "SET_SANDBOX_LOGO":
      return {
        ...state,
        logo: action.value
      }

    case "SET_SANDBOX_FOREGROUND_COLOR":
      return {
        ...state,
        foregroundColor: action.value
      }

    case "SET_SANDBOX_SECONDARY_COLOR":
      return {
        ...state,
        secondaryColor: action.value
      }

    case "SET_SANDBOX_BACKGROUND_COLOR":
      return {
        ...state,
        backgroundColor: action.value
      }

    default:
      return state
  }
}