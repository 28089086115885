import React, { useEffect } from "react"
import Section from "components/Section"
import "./index.scss";
import { setSandboxMode } from "redux/actions";
import { connect } from "react-redux";
import ColorCombo from "components/ColorCombo";

const Specimin = () => (
  <div className="font-specimin">
    <p style={{ textTransform: "uppercase" }}>abcdefghijklmnopqrstuvwxyz</p>
    <p>abcdefghijklmnopqrstuvwxyz</p>
    <p>1234567890</p>
  </div>
)

const Type = ({ data, setSandboxMode }) => {
  const { items, styles } = data

  useEffect(() => {
    setSandboxMode('text')
  },[])

  return <>
    <Section>
      <div className="Type">
        {items.map(face => <Face key={face.name} {...face} />)}
      </div>
      <div className="Type-styles">
        {styles.map(style => <Style key={style.style} {...style} />)}
      </div>
    </Section>
    <Section>
      <ColorCombo />
    </Section>
  </>
}

const mapStateToProps = state => ({
  data: state.data.type
})
const mapDispatchToProps = (dispatch) => ({
  setSandboxMode: (value) => dispatch(setSandboxMode(value))
})
export default connect(mapStateToProps,mapDispatchToProps)(Type);

const Face = ({ name, className, explainer, credit }) => {
  return (
    <div className={`Type-face`}>
      <div className={`Type-face-heading font-label`}>{name}</div>
      <div className="Type-face-explainer font-body">
        {explainer}
      </div>
      <div className="Type-face-credit font-credit">
        {credit}
      </div>
      <div className={`Type-face-specimin ${className}`}>
        <Specimin />
      </div>

    </div>
  )
}

const Style = ({
  style,
  className,
  ipsum,
  family,
  meta
}) => {

  return (
    <div className="Type-style">
      <div className="Type-style-name font-heading">{style}</div>
      <div className="Type-style-meta">
        <dl className="Type-style-meta-details font-body">
          <dt className="font-sublabel">Family</dt> <dd>{meta.family}</dd>
          <dt className="font-sublabel">Size</dt> <dd>{meta.size}</dd>
          <dt className="font-sublabel">Weight</dt> <dd>{meta.weight}</dd>
          <dt className="font-sublabel">Line Height</dt> <dd>{meta.lineHeight}</dd>
          {meta.tracking && (<><dt className="font-sublabel">Tracking</dt> <dd>{meta.tracking}</dd></>)}
          <dt className="font-sublabel">Case</dt> <dd>{meta.capitalization}</dd>
        </dl>
      </div>
      <div className={`Type-style-ipsum ${className}`}>{ipsum}</div>
    </div>
  )
}
