import React from "react";
import "./index.scss";
import { DownloadFormat } from "components/AssetDownload/Formats";

export default ({ className="", heading, explainer, fontStyle, inlineAsset, downloadableAsset, postExplainer, children }) => {
  const vidExt = /([a-zA-Z0-9\s_\\.\-\(\):])+(.mp4)$/
  // const imgExt = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.png)$/
  const fontClass = fontStyle && fontStyle.value ? fontStyle.value : 'font-intro'

  const asset = inlineAsset ? (inlineAsset.match(vidExt) ?
    <video autoPlay muted playsInline loop style={{ width: '100%; paddingTop: 2rem' }}>
      <source src={inlineAsset} />
    </video>
    : <img src={inlineAsset} style={{ width: '100%' }} alt={heading} />)
  : ''

  const downloadLinks = downloadableAsset ?
    downloadableAsset.map((link, i) =>
    <DownloadFormat key={ link.assetName + i } href={ link.url } name={ link.assetName }>
      { link.assetTitle }
    </DownloadFormat>
  ) : ''

  return (
    <div className={`Section contain ${className}`}>
      <SectionHeader heading={heading} explainer={explainer} fontClass={fontClass} />
      {
        children ?
        (<div className="Section-inner">{children}</div>)
        : ''
      }
      {postExplainer && <div className={`Section-inner section-contain ${fontClass}`}>{postExplainer}</div>}
      {asset}

      {downloadLinks ? <div className="Download-links Grid Grid-module--4" style={{ margin: '2rem 0' }}>{downloadLinks}</div> : ''}
    </div>
  )
}

export const SectionHeader = ({ heading, explainer, fontClass }) => {
  return (heading || explainer) ? (
    <div className="Section-header">
      {heading && <div className="Section-heading font-heading">{heading}</div>}
      {explainer && <div className={`Section-explainer ${fontClass}`} dangerouslySetInnerHTML={{__html: explainer}} />}
    </div>
  ) : null
}
