import React, { useEffect, useState } from "react";
import { ReactComponent as Down } from "assets/down.svg";
import DownloadSvg from "components/DownloadSvg";
import data from "data.js";

import { styleMarkup } from "helpers.js";

export default ({ items }) => {
  return (
  <div className="Logos">
    <div className="Logos-section">
      <div className="Logos-section-grid">
        {items.map(logo => <Logo key={logo.id} {...logo} />)}
      </div>
    </div>
  </div>
  )
}

const Logo = ({
  assetType,
  assetUrl,
  authors,
  backgroundColor,
  dynamicAsset,
  id,
  primaryColor,
  secondaryColor,
  title
}) => {
  const [svgText,setSvgText] = useState()
  useEffect(() => {
    fetch(assetUrl)
      .then(r=>r.text())
      .then(text => {
        setSvgText(text);
      })
  },[])

  const colors = Object.keys(data.color.items).map(color => data.color.items[color])
  const svgId = "Logo_" + title.replace(/\s/g,'');
  const twotoned = !!secondaryColor
  const bgColor = colors.filter(color => color.hex === backgroundColor)[0]
  const defaultColor = colors.filter(color => color.hex === primaryColor)[0]
  const accentColor = secondaryColor ? colors.filter(color => color.hex === secondaryColor)[0] : null

  return (
    <div className={`Logo Logo--half`}>
      <div className="Logo-inner">
        {svgText && (<DownloadSvg
          name={title}
          logoMarkup={styleMarkup(svgText,title,defaultColor,null,bgColor,true)}
          color={defaultColor ? defaultColor.key : null}
          backgroundColor={bgColor ? bgColor.key : null}
          secondaryColor={accentColor ? accentColor.key : null}
          twotoned={twotoned}
          dynamicAsset="true">
          <div
            id={svgId}
            className="Logo-image hover--scale"
            style={{ backgroundColor: bgColor.hex }}
            dangerouslySetInnerHTML={{ __html: styleMarkup(svgText,svgId,defaultColor,accentColor,bgColor,true) }}
          />

          <div className="hover-mark font-action"  style={{
            backgroundColor: defaultColor.hex,
            color: bgColor.hex
          }}>
            <div>Download </div><Down stroke={bgColor.hex} />
          </div>
        </DownloadSvg>)}
        <div className="Logo-title">{title}</div>
      </div>
    </div>
  )
}
