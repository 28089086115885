import React, { useEffect, useState } from "react";
import AutosizeInput from 'react-input-autosize';
import { round } from "./helpers.js";

export const Sizes = ({ size, setSize }) => {
  return <div className="AssetDownload-controls-sizes">
    <Size dimension={"width"} label="W" size={size} setSize={setSize} />
    <Size dimension={"height"} label="H" size={size} setSize={setSize} />
  </div>
}

export const Size = ({ dimension, label, size, setSize }) => {
  const MIN_SIZE = 50
  const MAX_SIZE = 10000
  const [value,setValue] = useState(size[dimension]);
  const [error,setError] = useState();

  useEffect(() => {
    setValue(size[dimension])
    setError(null)
  }, [size[dimension]])

  useEffect(() => {
    const resizeTimer = setTimeout(() => {
      const newValue = +value;
      if (!newValue) {
        setError("Invalid value")
      } else if (newValue < MIN_SIZE) {
        setError(`Too small.  Try something more than ${MIN_SIZE.toLocaleString()}px`)
      } else if (newValue > MAX_SIZE) {
        setError(`Too big.  Try something less than ${MAX_SIZE.toLocaleString()}px`)
      } else {
        //success!
        setSize({
          ...size,
          [dimension]: +value
        })
      }
    }, 500)

    return () => {
      clearTimeout(resizeTimer)
    }
  }, [value])
  return (
    <label className="AssetDownload-controls-sizes-size">
      {label}
      <AutosizeInput
        value={round(value)}
        onChange={(e) => {
          setValue(e.target.value)
          setError(null)
        }} />px
      {error && <div className="font-error error">{error}</div>}
    </label>
  )
}