const defaultState = {
  navBar: 'light',
  navLogo: 'hidden'
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SET_NAVBAR_STYLE":
      return {
        ...state,
        navBarStyle: action.value.style,
        navLogoVisible: action.value.navLogoVisible
      }

    default:
      return state
  }
}
