import React, { useEffect } from "react";

const NgpForm = (items) => {
  console.log('form init')

  useEffect(() => {
    if (!('nvtag'in window)) {
      const script = document.createElement('script');
        script.src = "https://d1aqhv4sn5kxtx.cloudfront.net/actiontag/at.js";
        script.id = "formEmbed";
        script.crossOrigin = 'anonymous';
        script.onload = () => {
          console.log('script load')
          //if (callback) callback();
        };
      document.body.appendChild(script);
    }
  },[])

  const formId = items.formUrl
    .replace('http://','')
    .replace('https://','')
    .replace('secure.ngpvan.com/','')


  return <div className="ngp-form"
    data-form-url={"https://actions.ngpvan.com/v1/Forms/" + formId}
    data-fastaction-endpoint="https://fastaction.ngpvan.com"
    data-inline-errors="true"
    data-fastaction-nologin="true"
    data-databag-endpoint="https://profile.ngpvan.com"
    data-databag="everybody">
  </div>;
}

export default NgpForm;
