import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./index.scss";
import { useTransition, animated } from "react-spring";
import { Link } from "react-router-dom";
import { useSpring } from "react-spring";
import { setNavbarStyle } from "redux/actions"
import { ReactComponent as Pete } from "assets/Pete2020.svg";
import Menu from "./menu.js";
import { isMobile } from "helpers";

import { TextNav as Nav } from "components/Nav";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const NavBar = ({ data, colors, navbarTheme, navLogoVisible, pageStyle, setNavbarStyle, match }) => {
  const {
    heartland,
    skagen,
    strato,
    claeys
  } = colors;
  const themes = {
    dark: {
     backgroundColor: strato.hex,
     stroke: claeys.hex,
     fill: claeys.hex,
     color: claeys.hex
    },
    light: {
      backgroundColor: heartland.hex,
      stroke: skagen.hex,
      fill: skagen.hex,
      color: skagen.hex
    },
    home: {
      backgroundColor: isSafari ? heartland.hex : "rgba(242,186,66,0)",
      stroke: skagen.hex,
      fill: skagen.hex,
      color: skagen.hex
    },
    menu: {
      backgroundColor: claeys.hex,
      stroke: strato.hex,
      fill: strato.hex,
      color: strato.hex
    }
  }

  const logoTransition = useTransition(navLogoVisible,null, {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
    unique: true
  })

  const navbarColorTheme = navbarTheme.theme ? navbarTheme.theme : 'light'
  const [menuOpen,setMenuOpen] = useState(false);
  const transitions = useTransition(menuOpen,null, {
    from: {
      transform: "translateY(-100%)"
    },
    enter: {
      transform: "translateY(0%)"
    },
    leave: {
      transform: "translateY(-100%)"
    },
    unique: true
  })

  useEffect(() => {
    setStyle(themes[menuOpen ? "menu" : navbarColorTheme])
  }, [menuOpen,navbarColorTheme])

  function closeMenu() {
    setMenuOpen(false)
  }

  const [style,setStyle] = useSpring(() => ({
    from: themes[navbarColorTheme]
  }))

  const mobileNavStyle = menuOpen && isMobile ? {
    height: '100%',
    overflow: 'auto',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  } : null

  return (
    <div className="NavBar" style={mobileNavStyle}>
      <animated.div className="NavBar-bar" style={style}>
        <a className="NavBar-mainlink NavBar-borderlink ff-aktiv bold"
          href="https://peteforamerica.com"
          target="_blank"
          rel="noopener noreferrer">Paid for by Pete for America</a>
        {logoTransition.map(({ item, props, key }) => item && (
          <animated.div className={`NavBar-bar-homelink`} style={props} key={key}>
            <Link to="/" onClick={closeMenu}>
              <Pete />
            </Link>
          </animated.div>
        ))}
        <div className="NavBar-right">
          <button
            className="NavBar-toggle"
            onClick={() => {
              setMenuOpen(!menuOpen)
            }}>
            <Menu open={menuOpen} />
          </button>
        </div>
      </animated.div>
      {transitions.map(({ item, props, key }) => item && (
        <animated.div key={key} style={props} className="NavBar-menu">
          <animated.div style={style}>
            <Nav data={data} onClick={closeMenu} />
          </animated.div>
        </animated.div>
      ))}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    data: state.data,
    colors: state.data.color.items,
    navbarTheme: state.style.navBar,
    navLogoVisible: state.style.navLogoVisible
  }
}
const mapDispatchToProps = dispatch => ({
  setNavbarStyle: (value) => {
    dispatch(setNavbarStyle(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(NavBar)
