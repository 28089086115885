import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

export const styleComponent = (Component,id="Download",color,secondaryColor,backgroundColor) => {
  id = id.replace(/[^0-9a-zA-Z]/g,"")
  const StyledComponent = <Component id={id} xmlns="http://www.w3.org/2000/svg" />
  const staticMarkup = renderToStaticMarkup(StyledComponent);

  return styleMarkup(staticMarkup,id,color,secondaryColor,backgroundColor)
}

export const styleMarkup = (markup,id,color,secondaryColor,backgroundColor,dynamicAsset) => {
  id = id.replace(/[^0-9a-zA-Z]/g,"")

  markup = markup
    .replace(/id="(?!secondary).*?"/,"")
    .replace(`<?xml version="1.0" encoding="UTF-8"?>`,"")
    .replace(/<!--[\s\S]*-->/,"")
    .trim()

  markup = [markup.slice(0,5),`id="${id}"`,markup.slice(5)].join(' ')
  markup = addBgRect(markup)

  const splitStaticMarkup = markup.split(/([\s\S]*?)><([\s\S]*)/);
  const styledMarkup = splitStaticMarkup[1] + `
    >
      ${dynamicAsset ? styleTag(color,secondaryColor,backgroundColor,id) : styleTag(null,null,backgroundColor,id)}
    <`.trim() + splitStaticMarkup[2];

  return styledMarkup
}

export const addBgRect = (existingMarkup) => {
  const [markup,viewBox] = removeBgRect(existingMarkup);
  if (!viewBox) {
    return existingMarkup
  } else {
    let width = +viewBox[3];
    let height = +viewBox[4];

    const splitMarkup = markup.split(/([\s\S]*?)>\s*?<([\s\S]*)/);

    const result = [
      splitMarkup[1],
      `><rect
        id="bg"
        x="${-viewBox[1]/2}"
        y="${-viewBox[2]/2}"
        width="${width}"
        height="${height}" /><`,
      splitMarkup[2]
    ].join('')

    return result
  }
}

export const removeBgRect = (markup) => {
  const viewBox = markup.match(/viewBox="([.\d]*) ([.\d]*) ([.\d]*) ([.\d]*)"/);
  if (!viewBox) {
    console.log(markup)
  } else {
    let width = +viewBox[3];
    let height = +viewBox[4];
    //remove existing rect
    const exp = `(<rect[^]*?width="${width}"[^]*?height="${height}"[^]*?)(></rect>|/>)`
    const re = new RegExp(exp,"g");
    markup = markup.replace(re,"")
  }

  return [markup,viewBox]
}

export const styleTag = (primaryColor,secondaryColor,backgroundColor,id="Download") => {
  id = id.replace(/[^0-9a-zA-Z]/g,"")
  return `<style>
    svg#${id} {
      display: block;
      width: 100%;
      height: auto;
    }
    ${primaryColor ? `
      svg#${id} * {
        fill: ${primaryColor.hex};
      }
    `: ''}
    svg#${id} #bg {
      fill: ${backgroundColor ? backgroundColor.hex : '#eef4f8'};
    }
    ${secondaryColor ? `
      svg#${id} #secondary,
      svg#${id} #secondary * {
        fill: ${secondaryColor.hex};
      }
    `: ''}
  </style>`
}

export const filterColors = (enabledColors, allColors, logoIsTwotone) => enabledColors.reduce((r,d) => {
    if (Array.isArray(d)) {
      if (logoIsTwotone) {
        r.push({
          key: d.join(""),
          name: d.map(d => allColors[d].name).join(" and "),
          colors: d.map(d => allColors[d].hex),
          keys: d
        })
      }
    } else {
      r.push({
        key: allColors[d].key,
        keys: [allColors[d].key],
        name: allColors[d].name,
        colors: [allColors[d].hex],
        foregrounds: allColors[d].foregrounds
      })
    }
    return r
  },[]);

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || /iPhone|iPad|iPod|Android/i.test(navigator.platform)
